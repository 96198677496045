import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const authToken = localStorage.getItem('authToken');
    const authReq = req.clone({
        setHeaders: {
            Authorization: `Bearer ${authToken}`
        }
    });

    return next(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
                Swal.fire('Sesión Expirada', 'Tu sesión ha expirado, por favor inicia sesión nuevamente.', 'warning');
                localStorage.removeItem('authToken');  // Limpia el token
                new Router().navigate(['/login']);  // Redirige al login
            }
            return throwError(error);
        })
    );

    // return next(authReq);
};
