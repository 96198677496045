import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthResolver } from './core/resolvers/auth.resolver';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./auth/auth.routes').then(m => m.AUTH_ROUTES)
    },
    {
        path: 'wizard',
        loadChildren: () => import('./wizard/wizard.routes').then(m => m.WIZARD_ROUTES),
        canActivate: [AuthGuard],
        resolve: { auth: AuthResolver }
    },
    {
        path: 'management',
        loadChildren: () => import('./management/management.routes').then(m => m.MANAGEMENT_ROUTES),
        canActivate: [AuthGuard],
        resolve: { auth: AuthResolver }
    },
    {
        path: '**',
        redirectTo: 'login'
    }
];
