import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { LoadingService } from './core/services/loading.service';
import { LoadingSpinnerComponent } from './shared/layouts/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    LoadingSpinnerComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'STEAM - Gen';

  constructor(private router: Router, private loadingService: LoadingService) {
    this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
            this.loadingService.show();
        } else if (event instanceof NavigationEnd) {
            this.loadingService.hide();
        }
    });
  }
}
